import React from "react"
import { graphql } from "gatsby"

import Header from "../components/header"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {

    return (
      <>
        <SEO title="404: Not Found" />
        <Header text={"Page not found - Looks like this page doesn't exist yet..."} />
      </>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`